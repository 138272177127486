









































































import { Component, Watch } from "vue-property-decorator"
import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"
import TitleShape from "@/components/TitleShape.vue"
import ViewWrapper from "@/views/ViewWrapper.vue"
import News from "@/types/News/News"
import NewsApi from "@/api/News/NewsApi"
import { deserialize } from "typescript-json-serializer"
import _ from "lodash"
import Year from "@/types/News/Year"
import moment from "moment"
import { mixins } from "vue-class-component"
import InputMixin from "@/types/InputMixin"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "NewsListPage",
  components: {
    ViewWrapper,
    SectionUnderLine,
    TitleShape
  }
})
export default class NewsListPage extends mixins(InputMixin, BreakpointMixin) {
  private loadMore = 1

  private maxLoadMore = 1

  private news: News[] = []

  private years: Year[] = []

  private year: number = moment().year()

  get selectionWidth() {
    const bp = this.$vuetify.breakpoint
    let width = 0
    if (bp.smAndDown) {
      width = 307
    } else {
      width = 486
    }
    return `${width}px`
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    const yearQuery = this.$route.query.year
    if (yearQuery) {
      this.year = parseInt(yearQuery as string, 10)
    }
    NewsApi.listYears()
      .then(({ data }) => {
        this.years = _.orderBy(
          _.map(data, (d) => deserialize<Year>(d, Year)),
          "year",
          "desc"
        )
        // // eslint-disable-next-line prefer-destructuring
        // this.year = this.years[0].year;
      })
      .then(this.fetchNews)
  }

  mounted() {
    this.fetchData()
  }

  fetchNews() {
    NewsApi.listNews(this.year).then(({ data }) => {
      this.news = _.sortBy(
        _.map(data, (d) => deserialize<News>(d, News)),
        "sort"
      )
      this.maxLoadMore = Math.ceil(this.news.length / 6)
    })
  }

  @Watch("year")
  onYearChange() {
    this.$router.push({ query: { ...this.$route.query, year: `${this.year}` } })
  }

  toNewsDetail(id: number) {
    this.$router.push({
      name: "NewsDetailPage",
      params: { id: `${id}` }
    })
  }

  private generateRoute(id: number) {
    return { name: "NewsDetailPage", params: { id: `${id}` } }
  }
}
